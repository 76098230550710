<template>
  <thead>
    <!--colgroup>
      <col style="width:4%">
      <col style="width:7%">
      <col style="width:7%">
      <col style="width:7%">
      <col style="width:5%">
      <col style="width:7%">
      <col style="width:10%">
      <col style="width:10%">
      <col style="width:10%">
      <col style="width:7%">
      <col style="width:7%">
      <col style="width:7%">
      <col style="width:7%">
    </colgroup-->
    <tr>
      <th v-if="headInfo.fstColumn" class="funcHead" :style="headInfo.style"></th>
      <template v-if="Array.isArray(headInfo.dataList)">
        <th v-for="(item, index) in headInfo.dataList" :key="index">
          <!-- <div class="title">{{$t(`table.head.${item.key}`)}}</div>
          <div v-if="item.sort" class="sort">
            <button class="sort up" :class="{'active': (item.sortKey || item.key) === orderColumn && orderType === 'ASC'}" @click="$emit('sort', item.sortKey || item.key, 'ASC')"> > </button>
            <button class="sort down" :class="{'active': (item.sortKey || item.key) === orderColumn && orderType === 'DESC'}" @click="$emit('sort', item.sortKey || item.key, 'DESC')"> > </button>
          </div> -->
          <div class="title" v-if="!item.sort">{{$t(`table.head.${item.key}`)}}</div>
          <div class="title" v-else :class="{'point' : item.sort}">
            <div v-if="orderType === 'DESC'">
              <div @click="sortColumn(item, 'ASC')" :class="{ 'active1': orderColumn === (item.sortKey || item.key) && orderType === 'DESC' }">
                {{$t(`table.head.${item.key}`)}}
                <button class="arrow" :class="{ 'active1': orderColumn === (item.sortKey || item.key) && orderType === 'DESC' && item.sort }">↓</button>
              </div>
            </div>
            <div v-else>
              <div @click="sortColumn(item, 'DESC')" :class="{ 'active2': orderColumn === (item.sortKey || item.key) && orderType === 'ASC' }">
                {{$t(`table.head.${item.key}`)}}
                <button class="arrow" :class="{ 'active2': orderColumn === (item.sortKey || item.key) && orderType === 'ASC' && item.sort }">↑</button>
              </div>
            </div>
          </div>
        </th>
      </template>
      <template v-if="!Array.isArray(headInfo.dataList)">
        <th v-for="(option,key) in headInfo.dataList" :style="option?{width:option+'%'}:''" :key="key">{{$t(`table.head.${key}`)}}</th>
      </template>
    </tr>
  </thead>
</template>

<script>
import _debounce from 'lodash/debounce'

export default {
  name: 'TableHead',
  props: [
    'headInfo',
    'orderColumn',
    'orderType'
  ],
  watch: {
    orderColumn () {
      console.log('11111111', this.orderColumn)
    }
  },
  methods: {
    sortColumn (item, type) {
      console.log('22222', item, type)
      this.$emit('sort', item.sortKey || item.key, type)
    }
  },
  created () {
    console.log('1111 ', this.orderColumn, this.orderType, this.headInfo)
  }
}
</script>

<style scoped>
button{
  height:40px;
  border:1px solid black;
}
th {
   height: 50px;
   background: #fbfbfb;
   color: #3d3d3d;
   vertical-align: middle;
   font-size:13px;
   white-space: nowrap;
}
/* div.title {
  cursor: pointer;
} */
.title.point{
  cursor: pointer;
  font-weight: 800;
}
div.sort {
  position: absolute;
  top:0;
  right: 0;
  width: 24px;
}
button.sort {
  border: inherit;
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #6f6565;
  z-index: 9;
  width: 18px;
  height: 18px;
  background-color: rgba(0, 0, 0, 0);
}
.sort.up {
  /*rotate: 270deg;*/
  transform: rotate(270deg);
}

.sort.down {
  /*rotate: 90deg;*/
  transform: rotate(90deg);
}

.sort.active {
  color: #fff;
}

.funcHead{
  width:0;
}
.arrow {
  display: none;
  border: 0;
  background: none;
  cursor: pointer;
  z-index: -1;
}
.arrow.active1, .arrow.active2 {
  display: inline-block;
}
</style>
